import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { MothershipData } from './mothership-data';
import { Currency } from '../enum/currency';

export enum PaymentSpecificationType {
  Card = 'Card',
  Invoice = 'Invoice',
  AvtaleGiro = 'AvtaleGiro',
  CreditNote = 'CreditNote',
  V2 = 'V2',
}

export class PaymentSpecification implements Identifiable {
  id: string;
  type: PaymentSpecificationType;

  paymentId?: string;

  amount: number; // NOTE: After discount.
  currency: Currency;
  from: Date;
  to: Date;

  mothershipData?: MothershipData;

  created: Date;
  modified: Date;

  constructor(json: any, type: PaymentSpecificationType) {
    this.id = json.id;
    this.type = type;

    this.paymentId = json.paymentId ? json.paymentId : undefined;

    this.amount = Number(json.amount);
    this.currency = json.currency as Currency;
    this.from = new Date(json.from);
    this.to = new Date(json.to);

    this.mothershipData = json.mothershipData ? new MothershipData(json.mothershipData) : undefined;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<PaymentSpecification> {
    return new (class implements Factory<PaymentSpecification> {
      make(json: any): PaymentSpecification {
        switch (json.type) {
          case PaymentSpecificationType.Card:
            return new CardSpecification(json);
          case PaymentSpecificationType.Invoice:
            return new InvoiceSpecification(json);
          case PaymentSpecificationType.CreditNote:
            return new CreditNoteSpecification(json);
          case PaymentSpecificationType.AvtaleGiro:
            return new AvtaleGiroSpecification(json);
          case PaymentSpecificationType.V2:
            return new V2PaymentSpecification(json);
          default:
            throw new Error('Unrecognized payment specification type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('Payment specifications are not stored in IndexedDB.');
      }
    })();
  }
}

export class CardSpecification extends PaymentSpecification implements Identifiable {
  constructor(json: any) {
    super(json, PaymentSpecificationType.Card);
  }
}

export class InvoiceSpecification extends PaymentSpecification implements Identifiable {
  constructor(json: any) {
    super(json, PaymentSpecificationType.Invoice);
  }
}

export class AvtaleGiroSpecification extends PaymentSpecification {
  constructor(json: any) {
    super(json, PaymentSpecificationType.AvtaleGiro);
  }
}

export class CreditNoteSpecification extends PaymentSpecification {
  constructor(json: any) {
    super(json, PaymentSpecificationType.CreditNote);
  }
}

export class V2PaymentSpecification extends PaymentSpecification {
  constructor(json: any) {
    super(json, PaymentSpecificationType.V2);
  }
}
