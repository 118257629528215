import { CardTransactionError, CardTransactionStatusV2, Factory, PaymentServiceProvider, ReminderChannel } from '../..';

export enum PaymentEventType {
  CardTransactionSucceeded = 'CardTransactionSucceeded',
  CardTransactionFailed = 'CardTransactionFailed',
  InvoiceIssued = 'InvoiceIssued',
  AvtaleGiroIssued = 'AvtaleGiroIssued',
  CreditNoteIssued = 'CreditNoteIssued',
  ReminderSent = 'ReminderSent',
}

export class PaymentEvent {
  type: PaymentEventType;

  created: Date;
  constructor(json: any) {
    this.type = json.type;

    this.created = new Date(json.created);
  }

  public static getFactory(): Factory<PaymentEvent> {
    return new (class implements Factory<PaymentEvent> {
      make(json: any): PaymentEvent {
        switch (json.type) {
          case PaymentEventType.CardTransactionSucceeded:
            return new CardTransactionSucceeded(json);
          case PaymentEventType.CardTransactionFailed:
            return new CardTransactionFailed(json);
          case PaymentEventType.InvoiceIssued:
            return new InvoiceIssued(json);
          case PaymentEventType.AvtaleGiroIssued:
            return new AvtaleGiroIssued(json);
          case PaymentEventType.CreditNoteIssued:
            return new CreditNoteIssued(json);
          case PaymentEventType.ReminderSent:
            return new ReminderSent(json);
          default:
            throw new Error('Unrecognized PaymentEvents type (' + json.type + ').');
        }
      }

      getTableName(): string {
        throw new Error('getTableName() should never be called on a PaymentEvents.');
      }
    })();
  }

  public static getAliasId(event: PaymentEvent): number | undefined {
    const e = PaymentEvent.getFactory().make(event);

    if (
      e instanceof CardTransactionSucceeded ||
      e instanceof CardTransactionFailed ||
      e instanceof InvoiceIssued ||
      e instanceof AvtaleGiroIssued ||
      e instanceof CreditNoteIssued
    ) {
      return e.aliasId;
    } else {
      return undefined;
    }
  }

  public static getAmount(event: PaymentEvent): number | undefined {
    const e = PaymentEvent.getFactory().make(event);

    if (
      e instanceof CardTransactionSucceeded ||
      e instanceof CardTransactionFailed ||
      e instanceof InvoiceIssued ||
      e instanceof AvtaleGiroIssued ||
      e instanceof CreditNoteIssued
    ) {
      return e.amount;
    } else {
      return undefined;
    }
  }

  public static getInvoiceOrAvtalegiro(event: PaymentEvent): InvoiceIssued | AvtaleGiroIssued | undefined {
    const e = PaymentEvent.getFactory().make(event);

    if (e instanceof InvoiceIssued || e instanceof AvtaleGiroIssued) {
      return e;
    } else {
      return undefined;
    }
  }

  public static getInvoiceFee(event: PaymentEvent): number | undefined {
    const e = PaymentEvent.getFactory().make(event);

    if (e instanceof InvoiceIssued) {
      return e.invoiceFee;
    } else {
      return undefined;
    }
  }

  public static getCardFailedReason(event: PaymentEvent): string | undefined {
    const e = PaymentEvent.getFactory().make(event);

    if (e instanceof CardTransactionFailed) {
      return CardTransactionError.getFactory().make(e.reason).text;
    } else {
      return undefined;
    }
  }
}

export class CardTransactionSucceeded extends PaymentEvent {
  aliasId: number;
  voucherNumber: number;
  transactionStatus: CardTransactionStatusV2;
  amount: number;
  reference: string;
  provider: PaymentServiceProvider;
  paymentMethodId: string;

  constructor(json: any) {
    super(json);
    this.aliasId = json.aliasId;
    this.voucherNumber = json.voucherNumber;
    this.transactionStatus = new CardTransactionStatusV2(json.transactionStatus);
    this.amount = Number(json.amount);
    this.reference = json.reference;
    this.provider = json.provider;
    this.paymentMethodId = json.paymentMethodId;
  }
}

export class CardTransactionFailed extends PaymentEvent {
  aliasId: number;
  voucherNumber: number;
  amount: number;
  reason: CardTransactionError;
  paymentMethodId: string;

  constructor(json: any) {
    super(json);
    this.aliasId = json.aliasId;
    this.voucherNumber = Number(json.voucherNumber);
    this.amount = Number(json.amount);
    this.reason = new CardTransactionError(json.reason);
    this.paymentMethodId = json.paymentMethodId;
  }
}

export class InvoiceIssued extends PaymentEvent {
  aliasId: number;
  voucherNumber: number;
  amount: number;
  batch?: number;
  partlyPaid?: number;
  invoiceFee: number;
  lateFee?: number;
  dueDate: Date;
  paidDate?: Date;

  constructor(json: any) {
    super(json);
    this.aliasId = json.aliasId;
    this.voucherNumber = json.voucherNumber;
    this.amount = Number(json.amount);
    this.batch = json.batch;
    this.partlyPaid = json.partlyPaid !== undefined ? Number(json.partlyPaid) : undefined;
    this.invoiceFee = Number(json.invoiceFee);
    this.dueDate = new Date(json.dueDate);
    this.lateFee = json.lateFee !== undefined ? Number(json.lateFee) : undefined;
  }
}

export class AvtaleGiroIssued extends PaymentEvent {
  aliasId: number;
  voucherNumber: number;
  amount: number;
  batch?: number;
  dueDate: Date;
  paidDate?: Date;

  constructor(json: any) {
    super(json);
    this.aliasId = json.aliasId;
    this.voucherNumber = json.voucherNumber;
    this.amount = Number(json.amount);
    this.batch = json.batch;
    this.dueDate = new Date(json.dueDate);
    this.paidDate = json.paidDate ? new Date(json.paidDate) : undefined;
  }
}

export class CreditNoteIssued extends PaymentEvent {
  aliasId: number;
  voucherNumber: number;
  amount: number;
  note?: string;
  batch?: number;

  constructor(json: any) {
    super(json);
    this.aliasId = json.aliasId;
    this.voucherNumber = json.voucherNumber;
    this.amount = Number(json.amount);
    this.note = json.note ? json.note : undefined;
    this.batch = json.batch;
  }
}

export class ReminderSent extends PaymentEvent {
  channel: ReminderChannel;

  constructor(json: any) {
    super(json);
    this.channel = new ReminderChannel(json.channel);
  }
}
