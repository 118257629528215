import { CountryRole, GlobalRole, Role } from './role';
import { Deletable } from '../interface/deletable';
import { Identifiable } from '../interface/identifiable';
import { Factory } from '../interface/factory';
import { CountryCode } from '../enum/country-code';
import { Email } from './email';
import { Phone } from './phone';
import { MothershipData } from './mothership-data';

export class PostalAddress {
  address: string;
  sublocality?: string;
  postcode: string;
  city: string;
  province?: string;
  country: string;

  constructor(json: any) {
    this.address = json.address;
    this.sublocality = json.sublocality ? json.sublocality : undefined;
    this.postcode = json.postcode;
    this.city = json.city;
    this.province = json.province ? json.sublocality : undefined;
    this.country = json.country;
  }
}

export class DefaultPaymentMethod {
  id: string;
  organizationId: string;

  constructor(json: any) {
    this.id = json.id;
    this.organizationId = json.organizationId;
  }
}

export class User implements Deletable, Identifiable {
  id: string;
  aliasId?: string;
  alias?: Alias[];

  deleted?: boolean;

  test?: boolean;

  firstName: string;
  preferredName?: string;
  middleNames?: string;
  lastName: string;

  nids?: string[];
  dob?: string;

  roles: Role[];

  email?: Email;
  phone?: Phone;
  postalAddress: PostalAddress;
  defaultPaymentMethodId?: string;
  defaultPaymentMethods: DefaultPaymentMethod[];

  countries: string[];
  mothershipData?: MothershipData;

  created: Date;
  modified: Date;

  constructor(json: any) {
    this.id = json.id;

    this.aliasId = json.aliasId;
    this.alias = json.alias ? json.alias.map((a: any) => new Alias(a)) : [];
    this.deleted = json.deleted ? Boolean(json.deleted) : false;

    this.test = json.test ? Boolean(json.test) : false;

    this.firstName = json.firstName;
    this.preferredName = json.preferredName ? json.preferredName : undefined;
    this.middleNames = json.middleNames ? json.middleNames : undefined;
    this.lastName = json.lastName;

    this.nids = json.nids ? json.nids : [];
    this.dob = json.dob ? json.dob : undefined;

    const roleFactory: Factory<Role> = Role.getFactory();
    this.roles = json.roles ? json.roles.map((json: any) => roleFactory.make(json)) : [];

    const emailFactory: Factory<Email> = Email.getFactory();
    this.email = json.email ? emailFactory.make(json.email) : undefined;

    const phoneFactory: Factory<Phone> = Phone.getFactory();
    this.phone = json.phone ? phoneFactory.make(json.phone) : undefined;

    this.postalAddress = new PostalAddress(json.postalAddress);
    this.defaultPaymentMethodId = json.defaultPaymentMethodId ? json.defaultPaymentMethodId : undefined;
    this.defaultPaymentMethods = json.defaultPaymentMethods
      ? json.defaultPaymentMethods.map((json: any) => new DefaultPaymentMethod(json))
      : [];

    // This is a list of countries to which the user is attached (e.g. has a product in).
    this.countries = json.countries ?? [];
    this.mothershipData = json.mothershipData ? new MothershipData(json.mothershipData) : undefined;

    this.created = new Date(json.created);
    this.modified = new Date(json.modified);
  }

  public static getFactory(): Factory<User> {
    return new (class implements Factory<User> {
      make(json: any): User {
        return new User(json);
      }

      getTableName(): string {
        return 'users';
      }
    })();
  }

  static getUrl(userId?: string): string;
  static getUrl(userId: string): string {
    return '/users' + (userId ? '/' + userId : '');
  }

  getName(showMiddleName: boolean = false): string {
    return showMiddleName && this.middleNames
      ? this.firstName.trim() + ' ' + this.middleNames.trim() + ' ' + this.lastName.trim()
      : this.firstName.trim() + ' ' + this.lastName.trim();
  }

  getRolesByCountry(code: CountryCode): Role[] {
    return this.roles.filter(
      (r) => ((r instanceof CountryRole && r.code === code) || r instanceof GlobalRole) && r.scopes > 0,
    );
  }

  getNidByCountry(code: CountryCode): string {
    return this.nids?.find((n) => n.startsWith(code))?.slice(2) ?? '';
  }
}
export class Alias {
  id: string;
  organizationId: string;

  constructor(json: any) {
    this.id = json.id;
    this.organizationId = json.organizationId;
  }
}
