import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Deletable,
  Identifiable,
  Factory,
  HasCountryCode,
  Organization,
  Fault,
  AwaitingSubject,
  SubscriberSentinel,
  Subscription,
  User,
  Story,
  CountryCode,
  VehicleBrand,
  VehicleModel,
  ProductTemplate,
  Product,
  NewUserResponse,
  Discount,
  ProductStatus,
  ExtraPhoneVerification,
  LanguageCode,
  PaymentMethod,
  Currency,
  Payment,
  DiscountTemplate,
} from '../../models';
import { NewUserExtra } from 'src/app/models/class/new-user-extra';
import { Interest, Vehicle, VikingObject, VikingObjectType } from 'src/app/models/class/viking-object';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  //#region Fields

  private readonly apiUrl: string = environment.apiUrl;
  private readonly acceptHeader: string = environment.acceptHeader;

  private db?: IDBDatabase;

  private userPoll: Worker;
  private countryPoll: Worker;
  private cache?: UserCache;
  private countryCache?: CountryCache;

  private users$: AwaitingSubject<User>;
  private discounts$: AwaitingSubject<Discount>;
  private organizations$: AwaitingSubject<Organization>;
  private stories$: AwaitingSubject<Story>;
  private vehicleBrands$: AwaitingSubject<VehicleBrand>;
  private vehicleModels$: AwaitingSubject<VehicleModel>;
  private productTemplates$: AwaitingSubject<ProductTemplate>;
  private products$: AwaitingSubject<Product>;
  private paymentMethods$: AwaitingSubject<PaymentMethod>;
  private objects$: AwaitingSubject<VikingObject>;
  private payments$: AwaitingSubject<Payment>;

  //#endregion

  //#region Initialization

  constructor(private http: HttpClient) {
    this.discounts$ = new AwaitingSubject();
    this.users$ = new AwaitingSubject();
    this.organizations$ = new AwaitingSubject();
    this.stories$ = new AwaitingSubject();
    this.vehicleBrands$ = new AwaitingSubject();
    this.vehicleModels$ = new AwaitingSubject();
    this.productTemplates$ = new AwaitingSubject();
    this.products$ = new AwaitingSubject();
    this.paymentMethods$ = new AwaitingSubject();
    this.objects$ = new AwaitingSubject();
    this.payments$ = new AwaitingSubject();

    const self = this;
    const req = window.indexedDB.open('VikingWebShopDB', 3);

    req.onerror = function (event: any) {
      console.log('DB onerror: ', event.target.errorCode);
    };

    req.onsuccess = function (event: any) {
      self.db = req.result;
    };

    req.onupgradeneeded = function (event: IDBVersionChangeEvent) {
      const db = req.result;
      let store: IDBObjectStore;

      if (event.oldVersion < 1) {
        // Users
        store = db.createObjectStore('users', { keyPath: 'id' });
        // Organizations
        store = db.createObjectStore('organizations', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
        // Stories
        store = db.createObjectStore('stories', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
        // Vehicle Brands
        store = db.createObjectStore('vehicle_brands', { keyPath: 'id' });
        // Vehicle Models
        store = db.createObjectStore('vehicle_models', { keyPath: 'id' });
        store.createIndex('vehicleBrandId', 'vehicleBrandId', {
          unique: false,
        });
        // ProductTemplates
        store = db.createObjectStore('product_templates', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
        // Products
        store = db.createObjectStore('products', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
        store.createIndex('templateId', 'templateId', { unique: false });
        store.createIndex('userId', 'userId', { unique: false });
      }
      if (event.oldVersion < 3) {
        // PaymentMethods
        store = db.createObjectStore('payment_methods', { keyPath: 'id' });
        store.createIndex('id', 'id', { unique: false });
        store.createIndex('userId', 'userId', { unique: false });
      }

      if (event.oldVersion < 4) {
        // Payments
        store = db.createObjectStore('payments', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
        // Objects
        store = db.createObjectStore('objects', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
      }
      if(event.oldVersion < 5) {
        // Discounts
        store = db.createObjectStore('discounts', { keyPath: 'id' });
        store.createIndex('countryCode', 'countryCode', { unique: false });
      }
    };

    // User Poll
    this.userPoll = new Worker(new URL('./../../workers/user-poll.worker', import.meta.url));
    this.userPoll.onmessage = async function (e) {
      await self.isReady(); // Make sure db is ready for processing poll.

      const t = e.data.type as UserPollResponseType;
      switch (t) {
        case UserPollResponseType.TokenRefresh:
          if (self.cache) {
            self.cache.accessToken = e.data.data;
            localStorage.setItem('accessToken', self.cache.accessToken); // Persist.
          }
          break;
        case UserPollResponseType.Error:
          const m = e.data.data as UserPollErrorResponse;
          console.log('Error received from user poll worker: ' + m.text);
          break;
        case UserPollResponseType.Poll:
          const uc = self.cache;
          if (uc) {
            const d = e.data.data as UserPollResponse;
            if (d.data) {
              if (d.data.user) {
                await self.parsePollData(uc, [d.data.user], User, self.users$);
              }
              if (d.data.products) {
                await self.parsePollData(uc, d.data.products, Product, self.products$);
              }
              if (d.data.paymentMethods) {
                await self.parsePollData(uc, d.data.paymentMethods, PaymentMethod, self.paymentMethods$);
              }
              if (d.data.objects) {
                await self.parsePollData(uc, d.data.objects, VikingObject, self.objects$);
              }
              if (d.data.payments) {
                await self.parsePollData(uc, d.data.payments, Payment, self.payments$);
              }
              if(d.data.discounts){
                await self.parsePollData(uc, d.data.discounts, Discount, self.discounts$);
              }

              localStorage.setItem('userCursor' + uc.userId, d.data.etag);

              if (d.done) {
                uc.setFinalInitialPollPackage(d.id);
              }

              uc.setReceivedInitialPollPackage(d.id);
            }
          }
          break;
        case UserPollResponseType.ConnectionOk:
          console.log('User connection is ok.');
          break;
        case UserPollResponseType.ConnectionError:
          console.log('User connection is down.');
          break;
        default:
          throw new Error('Received unrecognized reponse type from user poll.');
      }
    };

    // Country Poll
    this.countryPoll = new Worker(new URL('./../../workers/country-poll.worker', import.meta.url));
    this.countryPoll.onmessage = async function (e) {
      await self.isReady(); // Make sure db is ready for processing poll.

      const t = e.data.type as CountryPollResponseType;
      switch (t) {
        case CountryPollResponseType.Error:
          let m = e.data.data as CountryPollErrorResponse;
          console.log('Error received from country poll worker: ' + m.text);
          break;
        case CountryPollResponseType.Poll:
          const d = e.data.data as CountryPollResponse;

          const oc = self.countryCache;
          if (oc) {
            if (d.data) {
              await self.parsePollData(oc, d.data.stories, Story, self.stories$);
              await self.parsePollData(oc, d.data.vehicleBrands, VehicleBrand, self.vehicleBrands$);
              await self.parsePollData(oc, d.data.vehicleModels, VehicleModel, self.vehicleModels$);
              await self.parsePollData(oc, d.data.productTemplates, ProductTemplate, self.productTemplates$);
              await self.parsePollData(oc, d.data.users, User, self.users$);

              localStorage.setItem('countryCursor' + oc.countryCode, d.data.etag);
            }
            if (d.done) {
              oc.setFinalInitialPollPackage(d.id);
            }
            oc.setReceivedInitialPollPackage(d.id);
          }
          break;
        case CountryPollResponseType.ConnectionOk:
          console.log('Country connection is ok.');
          break;
        case CountryPollResponseType.ConnectionError:
          console.log('Country connection is down.');
          break;
        default:
          throw new Error('Received unrecognized reponse type from Country poll.');
      }
    };

    // Initiate cache and start polling if local storage has data from previous login.
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const userId = localStorage.getItem('userId');

    if (accessToken !== null && refreshToken !== null && userId !== null) {
      const c = new UserCache(accessToken, refreshToken, userId);
      this.cache = c;

      // Start User poll.
      this.userPoll.postMessage({
        type: UserPollRequestType.Signin,
        apiUrl: this.apiUrl,
        acceptHeader: this.acceptHeader,
        accessToken: accessToken,
        refreshToken: refreshToken,
        userId: userId,
        etag: localStorage.getItem('userCursor' + userId) ?? '',
      });

      // Start country poll.

      const countryCode = localStorage.getItem('countryCode') as CountryCode;
      if (countryCode) {
        this.countryCache = new CountryCache(countryCode);
        this.countryPoll.postMessage({
          type: CountryPollRequestType.Signin,
          apiUrl: this.apiUrl,
          acceptHeader: this.acceptHeader,
          countryCode: countryCode,
          etag: localStorage.getItem('countryCursor' + countryCode) ?? '',
        });
      }
    }
  }

  //#endregion

  //#region Polling

  async parsePollData<T>(
    cache: UserCache | CountryCache,
    data: T[],
    classIdentifier: any,
    observable: AwaitingSubject<T>,
  ) {
    if (data) {
      const factory = classIdentifier.getFactory();
      for (let i = 0; i < data.length; i++) {
        try {
          const o = factory.make(data[i]);
          await this.putEntity(o, factory); // Update in db.
          if (cache.polled) {
            await observable.next(o); // NOTE: We only notify subscribers after polling is complete.
          }
        } catch (x) {
          console.log('Error parsing ' + classIdentifier.name + ' from poll.', x, data);
        }
      }
      if (data.length > 0 && cache.polled) {
        await observable.next(new SubscriberSentinel());
      }
    }
  }

  async batchParsePollData<T>(
    cache: UserCache | CountryCache,
    data: T[],
    classIdentifier: any,
    observable: AwaitingSubject<T>,
  ) {
    const db = this.db;
    if (!db) {
      throw new Error('DB not initialized.');
    }

    if (!data) {
      return;
    }

    //console.log('Data length: ' + data.length);

    const BATCH_SIZE: number = 10000;
    //console.log('Batch size: ' + BATCH_SIZE);

    const batches: number = Math.ceil(data.length / BATCH_SIZE);
    //console.log('Batches: ' + batches);

    const factory = classIdentifier.getFactory();
    const table = factory.getTableName();

    let currentIndex = 0;

    for (let currentBatch = 1; currentBatch <= batches; currentBatch++) {
      await new Promise((resolve, reject) => {
        const transaction = db.transaction([table], 'readwrite');
        const store = transaction.objectStore(table);

        let batchEnd = currentBatch * BATCH_SIZE - 1;
        batchEnd = batchEnd > data.length ? data.length : batchEnd;

        console.log({ currentIndex, batchEnd });

        while (currentIndex < batchEnd) {
          store.put(data[currentIndex]);
          if (observable.subCount > 0) {
            const o = factory.make(data[currentIndex]);
            observable.next(o);
          }
          currentIndex++;
        }

        transaction.oncomplete = (event) => {
          resolve(event);
        };

        transaction.onerror = (event) => {
          reject(event);
        };

        transaction.commit();
      });
    }

    if (data.length > 0 && cache.polled) {
      await observable.next(new SubscriberSentinel());
    }
  }

  async userHasPolled(): Promise<void> {
    if (!this.cache) {
      throw new Error('Checking if User has polled without being signed in.');
    }
    await this.isReady();
    await this.cache.hasPolled();
  }

  async countryHasPolled(): Promise<void> {
    if (!this.countryCache) {
      throw new Error("Can't await Country poll without having chosen a Country.");
    }
    await this.isReady();
    await this.countryCache.hasPolled();
  }

  //#endregion

  //#region Persistance

  public async putEntity<T>(entity: T, factory: Factory<T>): Promise<void> {
    await this.isReady();
    await new Promise<any>((resolve, reject) => {
      if (!this.db) {
        throw new Error('DB not initialized.');
      }
      try {
        const table = factory.getTableName();
        const os = this.db.transaction([table], 'readwrite').objectStore(table);
        const req = os.put(entity);

        req.onsuccess = (event) => {
          resolve(event);
        };

        req.onerror = (event) => {
          console.error('Error deleting database.', event);
          reject(event);
        };
      } catch (err) {
        console.log('Error saving entity in DB.', err);
        reject(err);
      }
    });
  }

  //#endregion

  //#region Communications

  private async send(
    method: string,
    endpoint: string,
    body?: object,
    cache?: UserCache,
    cursor?: string,
    refresh: boolean = true,
  ): Promise<object> {
    if (method === 'POST' || method === 'PUT') {
      if (!body) {
        throw new Error('Body can not be undefined for POST or PUT requests.');
      }
    } else if (body) {
      throw new Error('Only POST and PUT requests can have a body.');
    }

    const options: any = {
      headers: {
        Accept: this.acceptHeader,
      },
    };

    if (cache) {
      options['headers']['Authorization'] = 'Bearer ' + cache.accessToken;
    }

    if (method === 'PUT') {
      options['Content-Type'] = 'multipart/form-data;';
    }

    try {
      if (method === 'GET') {
        return await firstValueFrom(this.http.get(this.apiUrl + endpoint, options));
      } else if (method === 'POST') {
        return await firstValueFrom(this.http.post(this.apiUrl + endpoint, body, options));
      } else if (method === 'PUT') {
        return await firstValueFrom(this.http.put(this.apiUrl + endpoint, body, options));
      } else if (method === 'DELETE') {
        return await firstValueFrom(this.http.delete(this.apiUrl + endpoint, options));
      } else {
        throw new Error('Unrecognized method type.');
      }
    } catch (err: any) {
      if (err && err.status == 401 && cache !== null && refresh === true) {
        try {
          if (!cache) {
            throw new Error('Cache was uninitialized when refreshing token.');
          }
          await this.refreshToken();
          return this.send(method, endpoint, body, cache, cursor, false);
        } catch (x) {
          const f = err.error?.fault;

          if (f) {
            // Is this a fault?
            throw new Fault(f);
          } else {
            throw err;
          }
        }
      } else {
        const f = err.error?.fault;
        if (f) {
          // Is this a fault?
          throw new Fault(f);
        } else {
          throw err;
        }
      }
    }
  }

  //#endregion

  //#region Active

  getActiveUserId(): string | undefined {
    const c = this.cache;
    if (!c) {
      return undefined;
    }
    return c.userId;
  }

  async getActiveUser(): Promise<User | undefined> {
    const c = this.cache;
    if (!c) {
      return undefined;
    }

    if (c.user) {
      return c.user;
    } else {
      const u = await this.getUser(c.userId);
      c.setUser(u); // Refresh cache.
      return u;
    }
  }

  async setActiveUser(accessToken: string, refreshToken: string, userId: string) {
    // Persist.
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('userId', userId);

    const cache = new UserCache(accessToken, refreshToken, userId);

    this.cache = cache;

    this.userPoll.postMessage({
      type: UserPollRequestType.Signin,
      apiUrl: this.apiUrl,
      acceptHeader: this.acceptHeader,
      accessToken: cache.accessToken,
      refreshToken: cache.refreshToken,
      userId: cache.userId,
      etag: localStorage.getItem('userCursor' + cache.userId) ?? '',
    });
  }

  getActiveCountryCode(): CountryCode | undefined {
    const c = this.countryCache;
    if (!c) {
      return undefined;
    }

    return c.countryCode;
  }

  async setActiveCountryCode(code: CountryCode) {
    localStorage.setItem('countryCode', code);

    const countryCache = new CountryCache(code);

    // Start Country poll.
    this.countryPoll.postMessage({
      type: CountryPollRequestType.Signin,
      apiUrl: this.apiUrl,
      acceptHeader: this.acceptHeader,
      countryCode: code,
      etag: localStorage.getItem('countryCursor' + code) ?? '',
    });

    this.countryCache = countryCache;
  }
  //#endregion

  //#region Sign Up/In/Out

  async claimPhone(phone: string, language: LanguageCode) {
    if (!phone) {
      throw new Error('Trying to log in without phone.');
    }

    const body = { data: phone, extra: { language: language } };

    await this.send('POST', '/users/claim/phone', body);
    // TODO: Error handling??
  }

  async claimSpar(nid: string, extra: ExtraPhoneVerification) {
    if (!nid) {
      throw new Error('Trying to claim spar without nid.');
    }

    if (!extra) {
      throw new Error('Cant claim spar without extra information');
    }

    const requestPayload = {
      data: nid,
      extra: extra,
    };

    const res = (await this.send('POST', '/users/lookup/spar', requestPayload)) as DataResponse;

    if (!res.data) {
      throw new Error('Could not claim spar');
    }

    return res.data;
  }

  async signInByPhone(phone: string, verificationCode: string) {
    if (!phone) {
      throw new Error('Trying to log in without phone.');
    }

    if (!verificationCode) {
      throw new Error('Trying to log in without verificationCode.');
    }

    const res = (await this.send('POST', '/users/signin/phone', {
      data: phone,
      extra: { phoneVerificationCode: verificationCode },
    })) as SigninResponse;

    if (!res.data) {
      throw new Error('Response contained no data when logging in by phone.');
    }

    const cache = new UserCache(res.data.accessToken, res.data.refreshToken, res.data.userId);

    // Persist.
    localStorage.setItem('accessToken', cache.accessToken);
    localStorage.setItem('refreshToken', cache.refreshToken);
    localStorage.setItem('userId', cache.userId);

    this.cache = cache;

    this.userPoll.postMessage({
      type: UserPollRequestType.Signin,
      apiUrl: this.apiUrl,
      acceptHeader: this.acceptHeader,
      accessToken: cache.accessToken,
      refreshToken: cache.refreshToken,
      userId: cache.userId,
      etag: localStorage.getItem('userCursor' + cache.userId) ?? '',
    });
  }

  async verifyPhone(phone: string, code: string): Promise<boolean> {
    if (!phone) {
      throw new Error('Trying to verify phone without phone');
    }
    if (!code) {
      throw new Error('Trying to verify code without code');
    }

    const requestPayload = {
      data: phone,
      extra: code,
    };

    const res = await this.send('POST', '/users/claim/phone/verify', requestPayload);

    if ('data' in res && res.data === true) {
      return true;
    } else {
      throw new Error('Code did not match entered phonenumber');
    }
  }

  async claimEmail(email: string, language: LanguageCode, onlyClaimIfExisting: boolean = true): Promise<boolean> {
    if (!email) {
      throw new Error('Trying to claim email without email.');
    }

    const body = { data: email, extra: { onlyClaimIfExisting: onlyClaimIfExisting, language: language } };

    const res = (await this.send('POST', '/users/claim/email', body)) as DataResponse;

    return Boolean(res.data);
  }

  async signInByEmail(email: string, verificationCode: string) {
    if (!email) {
      throw new Error('Trying to log in without email.');
    }

    if (!verificationCode) {
      throw new Error('Trying to log in without verificationCode.');
    }

    const res = (await this.send('POST', '/users/signin/email', {
      data: email,
      extra: { emailVerificationCode: verificationCode },
    })) as SigninResponse;

    if (!res.data) {
      throw new Error('Response contained no data when logging in by email.');
    }

    const cache = new UserCache(res.data.accessToken, res.data.refreshToken, res.data.userId);

    // Persist.
    localStorage.setItem('accessToken', cache.accessToken);
    localStorage.setItem('refreshToken', cache.refreshToken);
    localStorage.setItem('userId', cache.userId);

    this.cache = cache;

    this.userPoll.postMessage({
      type: UserPollRequestType.Signin,
      apiUrl: this.apiUrl,
      acceptHeader: this.acceptHeader,
      accessToken: cache.accessToken,
      refreshToken: cache.refreshToken,
      userId: cache.userId,
      etag: localStorage.getItem('userCursor' + cache.userId) ?? '',
    });
  }

  async signOut() {
    this.userPoll.postMessage({ type: UserPollRequestType.Signout });
    // this.countryPoll.postMessage({ type: CountryPollRequestType.Signout });

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('countryCode');

    this.cache = undefined;
  }

  async conditionalSignOut() {
    if (this.cache) {
      await this.signOut();
    }
  }

  async resetCache() {
    this.userPoll.postMessage({ type: UserPollRequestType.Signout });
    // this.countryPoll.postMessage({ type: CountryPollRequestType.Signout });

    localStorage.clear();

    await new Promise<any>((resolve, reject) => {
      const req: IDBOpenDBRequest = indexedDB.deleteDatabase('VikingWebShopDB');

      req.onblocked = (event) => {
        console.log("Couldn't delete database due to the operation being blocked.", event);
        this.db?.close();
      };

      req.onerror = (event) => {
        console.error('Error deleting database.', event);
        reject(event);
      };

      req.onsuccess = (event) => {
        console.log('Database deleted successfully.', event);
        resolve(event); // event.result should be undefined on success
      };
    });

    this.cache = undefined;
  }

  async refreshToken() {
    if (!this.cache) {
      throw new Error('Cache service must be initialized before token refresh.');
    }

    const body = { data: this.cache.refreshToken };

    const res = (await this.send(
      'POST',
      '/users/' + this.cache.userId + '/token/refresh',
      body,
      this.cache,
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Token refresh response has no data.');
    }

    this.cache.accessToken = res.data;
    localStorage.setItem('accessToken', this.cache.accessToken); // Persist.
  }

  //#endregion

  //#region Subscription

  private async subscribe<T extends Deletable>(
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>,
    deleted: boolean = true,
    provideAll: boolean = true,
  ): Promise<Subscription<T>> {
    // Get active user.
    const c = this.cache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in.');
    }

    await this.isReady();

    const s = observable.subscribe({
      next: async (c) => {
        if (c instanceof SubscriberSentinel || deleted || !c.deleted) {
          await next(c);
        }
      },
    });

    // Provide all in db.
    if (provideAll) {
      const entities = await this.getAllEntitiesFromDb(factory);
      for (let i = 0; i < entities.length; i++) {
        if (deleted || !entities[i].deleted) {
          await next(entities[i]);
        }
      }
    }

    await next(new SubscriberSentinel()); // Send signal that all available have been sent.

    return s;
  }

  private async subscribeByCountry<T extends HasCountryCode>(
    countryCode: CountryCode,
    factory: Factory<T>,
    observable: AwaitingSubject<T>,
    next: (value: T | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<T>> {
    const c = this.countryCache;
    if (!c) {
      throw new Error('Cannot subscribe without being signed in country.');
    }

    await this.isReady();

    const sub = observable.subscribe({
      next: async (o) => {
        if (o instanceof SubscriberSentinel || o.countryCode === countryCode) {
          await next(o);
        }
      },
    });

    // Provide all in DB.
    const entities = await this.getEntitiesFromDbByIndex(countryCode, 'countryCode', factory);
    for (let i = 0; i < entities.length; i++) {
      const e: any = entities[i];
      if (e.countryCode === countryCode && !e.deleted) {
        await next(e);
      }
    }

    // Send signal that all available have been sent.
    await next(new SubscriberSentinel());

    return sub;
  }

  public async subscribeToUsers(
    next: (value: User | SubscriberSentinel) => Promise<void>,
    provideAll: boolean = true,
  ): Promise<Subscription<User>> {
    return this.subscribe(User.getFactory(), this.users$, next, true, provideAll);
  }

  public async subscribeToStories(
    countryCode: CountryCode,
    next: (value: Story | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<Story>> {
    return this.subscribeByCountry(countryCode, Story.getFactory(), this.stories$, next);
  }

  public async subscribeToVehicleBrands(
    next: (value: VehicleBrand | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<VehicleBrand>> {
    return this.subscribe(VehicleBrand.getFactory(), this.vehicleBrands$, next);
  }

  public async subscribeToVehicleModels(
    next: (value: VehicleModel | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<VehicleModel>> {
    return this.subscribe(VehicleModel.getFactory(), this.vehicleModels$, next);
  }

  public async subscribeToProductTemplates(
    countryCode: CountryCode,
    next: (value: ProductTemplate | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<ProductTemplate>> {
    return this.subscribeByCountry(countryCode, ProductTemplate.getFactory(), this.productTemplates$, next);
  }

  public async subscribeToProducts(
    next: (value: Product | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<Product>> {
    return this.subscribe(Product.getFactory(), this.products$, next);
  }

  public async subscribeToPaymentMethods(
    next: (value: PaymentMethod | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<PaymentMethod>> {
    return this.subscribe(PaymentMethod.getFactory(), this.paymentMethods$, next);
  }

  public async subscribeToObjects(
    next: (value: VikingObject | SubscriberSentinel) => Promise<void>,
  ): Promise<Subscription<VikingObject>> {
    return this.subscribe(VikingObject.getFactory(), this.objects$, next);
  }

  // public async subscribeToPayments(
  //   next: (value: Payment | SubscriberSentinel) => Promise<void>,
  // ): Promise<Subscription<Payment>> {
  //   return this.subscribe(Payment.getFactory(), this.payments$, next);
  // }
  //#endregion

  //#region Count
  //#endregion

  //#region Get

  public async getEntityFromApi<T>(url: string, factory: Factory<T>): Promise<T> {
    const res = (await this.send('GET', url, undefined, this.cache)) as any;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    return factory.make(res.data);
  }

  private async getEntity<T>(id: string, factory: Factory<T>, url: string, invalidate: boolean = false): Promise<T> {
    await this.isReady();

    // If old object is invalidated fetch latest from API right away.
    if (invalidate) {
      const e: T = await this.getEntityFromApi(url, factory);
      await this.putEntity(e, factory); // Update in db.
      return new Promise<T>((resolve) => resolve(e));
    }

    // Try to look in db.
    const self = this;
    return new Promise<T>(function (resolve, reject) {
      if (self.db == null) {
        throw new Error('DB was not initialized.');
      }

      // Check db.
      const table: string = factory.getTableName();
      const os = self.db.transaction([table], 'readonly').objectStore(table);
      const req = os.get(id);

      req.onerror = async function () {
        // Entity not found in db, fetch from server.
        try {
          const e: T = await self.getEntityFromApi(url, factory);
          self.putEntity(e, factory); // Update in db.
          resolve(e);
        } catch (x) {
          reject(x);
        }
      };

      req.onsuccess = async function () {
        // Entity found in db.
        try {
          let e: T;
          if (invalidate) {
            e = await self.getEntityFromApi(url, factory);
            self.putEntity(e, factory); // Update in db.
          } else {
            if (req.result) {
              e = factory.make(req.result);
            } else {
              e = await self.getEntityFromApi(url, factory);
              self.putEntity(e, factory); // Update in db.
            }
          }
          resolve(e);
        } catch (x) {
          reject(x);
        }
      };
    });
  }

  public async getAllEntitiesFromDb<T>(factory: Factory<T>): Promise<T[]> {
    await this.isReady();

    let self = this;

    const table: string = factory.getTableName();
    return new Promise<T[]>(function (resolve, reject) {
      let res: T[] = [];
      let os = self.db!.transaction([table], 'readonly').objectStore(table);
      let req = os.openCursor();
      req.onerror = async function (event: any) {
        reject(event);
      };
      req.onsuccess = function (_event) {
        let cursor = req.result;
        if (cursor) {
          const o = factory.make(cursor.value);
          res.push(o);
          cursor.continue();
        } else {
          resolve(res);
        }
      };
    });
  }

  public async getEntitiesFromDbByIndex<T>(range: any, index: string, factory: Factory<T>): Promise<T[]> {
    const self = this;
    const table = factory.getTableName();

    await this.isReady();
    return new Promise<T[]>(function (resolve, reject) {
      const res: T[] = [];
      const os = self.db!.transaction([table], 'readonly').objectStore(table);

      const req = (() => {
        const _range = IDBKeyRange.only(range);
        const indx = os.index(index);
        return indx.openCursor(_range);
      })();

      req.onerror = async function (event: any) {
        reject(event);
      };

      req.onsuccess = function (_event) {
        const cursor = req.result;
        if (cursor) {
          const o = factory.make(cursor.value);
          res.push(o);
          cursor.continue();
        } else {
          resolve(res);
        }
      };
    });
  }

  async getUser(id: string, invalidate: boolean = false): Promise<User> {
    return this.getEntity(id, User.getFactory(), User.getUrl(id), invalidate);
  }

  async getOrganization(id: string, invalidate: boolean = false): Promise<Organization> {
    return this.getEntity(id, Organization.getFactory(), Organization.getUrl(id), invalidate);
  }

  async getVehicleBrand(id: string, invalidate: boolean = false): Promise<VehicleBrand> {
    return this.getEntity(id, VehicleBrand.getFactory(), VehicleBrand.getUrl(id), invalidate);
  }

  async getVehicleModel(id: string, invalidate: boolean = false): Promise<VehicleModel> {
    return this.getEntity(id, VehicleModel.getFactory(), VehicleModel.getUrl(id), invalidate);
  }

  async getProductTemplate(id: string): Promise<ProductTemplate> {
    await this.isReady();
    const self = this;
    return new Promise<ProductTemplate>(function (resolve, reject) {
      if (self.db == null) {
        throw new Error('DB was not initialized.');
      }
      const factory = ProductTemplate.getFactory();
      // Check db.
      const table: string = factory.getTableName();
      const os = self.db.transaction([table], 'readonly').objectStore(table);
      const req = os.get(id);

      req.onerror = async function () {
        // Entity not found in db
        reject('ProductTemplate not found in db');
      };

      req.onsuccess = async function () {
        // Entity found in db.

        if (req.result) {
          const e = factory.make(req.result);
          resolve(e);
        } else {
          reject(new Error('ProductTemplates not found ' + id));
        }
      };
    });
  }

  // async getPaymentMethod(defaultPaymentMethodId: string): Promise<PaymentMethod> {
  //   if (!defaultPaymentMethodId) {
  //     throw new Error('No default payment method id');
  //   }

  //   const res = (await this.send(
  //     'GET',
  //     '/users/' + defaultPaymentMethodId + '/payment_methods',
  //     undefined,
  //     this.cache,
  //   )) as DataResponse;

  //   if (!res.data) {
  //     throw new Error('Data not found');
  //   }

  //   const factory = PaymentMethod.getFactory();

  //   return factory.make(res.data);
  // }

  async getPaymentsByUser(userId: string): Promise<Payment[]> {
    const res = (await this.send('GET', `/users/${userId}/payments`, undefined, this.cache)) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    // Save payments.
    const payments: Payment[] = [];
    const f = Payment.getFactory();
    for (let i = 0; i < res.data.length; i++) {
      const p: Payment = f.make(res.data[i]);
      // await this.putEntity(p, f);
      payments.push(p);
    }

    return payments;
  }

  async getPayment(id: string, invalidate: boolean = false): Promise<Payment> {
    return this.getEntity(id, Payment.getFactory(), Payment.getUrl(id), invalidate);
  }

  async getProductsByUser(userId: string): Promise<Product[]> {
    await this.isReady();

    const res = (await this.send('GET', `/users/${userId}/products`, undefined, this.cache)) as any;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const factory = Product.getFactory();

    for (let i = 0; i < res.data.length; i++) {
      const p: Product = factory.make(res.data[i]);
      await this.putEntity(p, factory);
    }

    const products = res.data.map((d: any) => factory.make(d));
    return products.filter((p: Product) => !p.deleted);
  }

  async getProduct(id: string, invalidate: boolean = false): Promise<Product> {
    return this.getEntity(id, Product.getFactory(), Product.getUrl(id), invalidate);
  }

  async getObject(countryCode: CountryCode, id: string, invalidate: boolean = false): Promise<VikingObject> {
    return this.getEntity(id, VikingObject.getFactory(), VikingObject.getUrl(countryCode, id), invalidate);
  }

  // async getDiscountTempalte(countryCode: CountryCode, id: string, invalidate: boolean = false): Promise<DiscountTemplate> {
  //   return this.getEntity(id, DiscountTemplate.getFactory(), DiscountTemplate.getUrl(countryCode, id), invalidate);
  // }

  async getDiscount(countryCode: CountryCode, id: string, invalidate: boolean = false): Promise<Discount> {
    return this.getEntity(id, Discount.getFactory(), Discount.getUrl(countryCode, id), invalidate);
  }

  async getVehicle(countryCode: CountryCode, vehicleId: string): Promise<Vehicle> {
    const o = await this.getEntity(
      vehicleId,
      VikingObject.getFactory(),
      VikingObject.getUrl(countryCode, vehicleId),
      false,
    );

    if (o.type === VikingObjectType.Vehicle) {
      return o as Vehicle;
    }

    throw new Error('Requested Object ' + vehicleId + ' was not of type VehicleObject.');
  }

  async getInterest(countryCode: CountryCode, interestId: string): Promise<Interest> {
    const o = await this.getEntity(
      interestId,
      VikingObject.getFactory(),
      VikingObject.getUrl(countryCode, interestId),
      false,
    );

    if (o.type === VikingObjectType.Interest) {
      return o as Interest;
    }

    throw new Error('Requested Object ' + interestId + ' was not of type Interest.');
  }
  

  public async addInterest(i: Interest): Promise<Interest> {
    if (!i) {
      throw new Error('Object must be present.');
    }
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }
    // TODO ????
    console.log('Interest', i);
    const res = (await this.send('POST', Interest.getUrl(i.countryCode), { data: i }, cache)) as DataResponse;
    console.log('Response Interest', res);

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const factory = Interest.getFactory();
    const obj = factory.make(res.data);

    if (obj instanceof Interest) {
      return obj;
    }

    throw new Error('Unexpected object type when adding interest.');
  }

  public async deleteInterest(countryCode: string, objectId: string): Promise<Interest> {
    if (!countryCode || !objectId) {
      throw new Error('Country code and object ID must be present.');
    }
  
    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot delete an object without being signed in.');
    }
  
    const url = `${this.apiUrl}/${countryCode}/${objectId.slice(2)}`;
    console.log('URL', url);
    const res = await this.send('DELETE', url, undefined, cache) as DataResponse; // Endret body til null
    console.log('Response', res);
    if (!res.data) {
      throw new Error('Data not found.');
    }
  
    const factory = Interest.getFactory();
    const obj = factory.make(res.data);
  
    if (obj instanceof Interest) {
      return obj;
    }
  
    throw new Error('Unexpected object type when deleting interest.');
  }
  
  

  public async addVehicleObject(v: Vehicle): Promise<Vehicle> {
    if (!v) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }

    console.log('Vehicle', v);
    const res = (await this.send('POST', Vehicle.getUrl(v.countryCode), { data: v }, cache)) as DataResponse;
    console.log('Response Vehicle', res);
    if (!res.data) {
      throw new Error('Data not found.');
    }

    const newVehicle: Vehicle = Vehicle.getFactory().make(res.data) as Vehicle;

    return newVehicle;
  }
  

  async claimDiscount(countryCode: CountryCode, discountCode: string): Promise<Discount> {
    await this.isReady();
    const c = this.cache;
    if (!c) {
      throw new Error('Can not claim discount without being signed in');
    }

    const res = (await this.send(
      'GET',
      `/users/${c.userId}/discounts/claim/${countryCode}${discountCode}`,
      undefined,
      c,
    )) as any;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    const factory = Discount.getFactory();
    const dc: Discount = factory.make(res.data);

    return dc;
  }

  async submitPaymentByNewCard(
    product: Product,
    firstPaymentPrice: string,
    language: string,
    type: string,
  ): Promise<string> {
    if (!this.cache) {
      throw new Error('Can not request payment without being signed in');
    }

    const body = {
      data: product,
      extra: {
        paymentMethod: {
          type: type, // ENUM this later.
          language: language, // no-NO || sv-SE
          makeDefaultPaymentMethod: true,
        },
        firstPaymentPrice: firstPaymentPrice, // Price after discount.
      },
    };

    const res = (await this.send('POST', `/countries/${product.countryCode}/products`, body, this.cache)) as any;

    return res.extra.token;
  }

  async submitPaymentByExistingCard(product: Product, firstPaymentPrice: string, language: string, type: string): Promise<Product>{
    if (!this.cache) {
      throw new Error('Can not request payment without being signed in');
    }

    const body = {
      data: product,
      extra: {
        paymentMethod: {
          type: type, // ENUM this later.
          language: language, // no-NO || sv-SE
          makeDefaultPaymentMethod: true,
        },
        firstPaymentPrice: firstPaymentPrice, // Price after discount.
      },
    };

    const res = (await this.send('POST', `/countries/${product.countryCode}/products`, body, this.cache)) as any;

    return res.data;
  }

  async submitProductStatus(url: string, product: Product): Promise<Product> {
    if (!this.cache) {
      throw new Error('Can not request payment without being signed in');
    }
    if (!this.countryCache) {
      throw new Error('Can not request payment without being signed in');
    }
    if (!product) {
      throw new Error('Can not request payment without being signed in');
    }

    const body = {
      data: ProductStatus.Ongoing,
      extra: {
        addCard: {
          acceptQueryParameters: url,
          note: undefined,
        },
      },
    };

    const res = (await this.send(
      'PUT',
      `/countries/${this.countryCache.countryCode}/products/${product.id}/status`,
      body,
      this.cache,
    )) as any;

    return res.data.extra;
  }

  //#endregion

  //#region Set
  //#endregion

  //#region Delete

  async deletePaymentMethod(userId: string, p: PaymentMethod): Promise<void> {
    await this.delete(PaymentMethod.getFactory(), p, PaymentMethod.getUrl(userId, p), this.paymentMethods$);
  }

  private async delete<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    url: string,
    observable: AwaitingSubject<T>,
  ): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    await this.isReady();

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }

    const res = (await this.send('DELETE', url, undefined, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    o = factory.make(res.data);
    await this.putEntity(o, factory); // Update in db.

    if (observable) {
      await observable.next(o);
      await observable.next(new SubscriberSentinel()); // Send signal that object has been sent.
    }

    return o;
  }

  //#endregion

  //#region Update

  private async edit<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    url: string,
    observable?: AwaitingSubject<T>,
  ): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot edit an object without being signed in.');
    }

    await this.isReady();

    const res = (await this.send('PUT', url, { data: o }, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    o = factory.make(res.data);
    await this.putEntity(o, factory); // Update in db.

    if (observable) {
      await observable.next(o);
      await observable.next(new SubscriberSentinel()); // Send signal that object has been sent.
    }

    return o;
  }

  public async editUser(u: User): Promise<User> {
    return this.edit(User.getFactory(), u, User.getUrl(u.id), this.users$);
  }

  //#endregion

  //#region Add

  private async add<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    url: string,
    observable?: AwaitingSubject<T>,
    extra?: any,
  ): Promise<T> {
    if (!o) {
      throw new Error('Object must be present.');
    }

    await this.isReady();

    const cache = this.cache;
    if (!cache) {
      throw new Error('Cannot add an object without being signed in.');
    }

    const body = extra ? { data: o, extra: extra } : { data: o };

    const res = (await this.send('POST', url, body, cache)) as DataResponse;
    if (!res.data) {
      throw new Error('Data not found.');
    }

    o = factory.make(res.data);
    await this.putEntity(o, factory); // Update in db.

    if (observable) {
      await observable.next(o);
      await observable.next(new SubscriberSentinel()); // Send signal that object has been sent.
    }

    return o;
  }

  // Accepts Base64 encoded file string and returns full URL to file.
  public async addFile(base64File: string): Promise<string> {
    if (!base64File) {
      throw new Error("Can't upload PDF without a file.");
    }

    const c = this.cache;
    if (!c) {
      throw new Error("Can't add file without being signed in.");
    }

    const body = { data: base64File };

    const res = (await this.send('POST', '/file/upload', body, this.cache)) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    return res.data;
  }

  private async addImageToEntity<T extends Identifiable>(
    factory: Factory<T>,
    o: T,
    base64File: string,
    url: string,
    observable?: AwaitingSubject<T>,
  ): Promise<T> {
    if (!o) {
      throw new Error("Can't add image without an entity.");
    }

    if (!base64File) {
      throw new Error("Can't add image without file.");
    }

    await this.isReady();

    const body = { data: { data: base64File } };
    const res = (await this.send('POST', url + '/images', body, this.cache)) as DataResponse;

    if (!res.data) {
      throw new Error('No data when adding image to object.');
    }

    const newEntity: T = factory.make(res.data);
    await this.putEntity(o, factory); // Update in db.

    if (observable) {
      await observable.next(newEntity);
      await observable.next(new SubscriberSentinel());
    }

    return newEntity;
  }

  public async addSjekkpunktProduct(o: Object): Promise<void> {
    (await this.send('POST', '/countries/NO/products/register/sjekkpunkt', {
      data: o,
    })) as DataResponse;
  }

  // public async addUser(u: User, extra?: NewUserExtra): Promise<NewUserResponse> {
  //   if (!u) {
  //     throw new Error('Object must be present.');
  //   }

  //   const cache = this.cache;
  //   (await this.send('POST', '/countries/NO/products/register/sjekkpunkt', {
  //     data: o,
  //   })) as DataResponse;
  // }

  // const body = {
  //   data: product,
  //   extra: {
  //     paymentMethod: {
  //       type: type, // ENUM this later.
  //       language: language, // no-NO || sv-SE
  //       makeDefaultPaymentMethod: true,
  //     },
  //     firstPaymentPrice: firstPaymentPrice, // Price after discount.
  //   },
  // };

  public async addNewCard(u: User, language: string, currency: Currency, orgId: string): Promise<string> {
    const getAddCardUrl = {
      type: 'GetAddCardUrl',
      language: language,
      currency: currency,
      makeDefaultPaymentMethod: true,
    };

    const res = (await this.send(
      'POST',
      `/users/${u.id}/payment_methods`,
      {
        extra: {
          action: getAddCardUrl,
          organizationId: orgId,
        },
      },
      this.cache,
    )) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    return res.data;
  }

  // const action = {
  //   paymentMethodId: guid,
  //   acceptQueryParameters: url,
  // }

  public async addCard(id: string, url: string, userId: string, orgId: string): Promise<DataResponse> {
    const addCard = {
      type: 'AddCard',
      paymentMethodId: id,
      acceptQueryParameters: url,
      makeDefaultPaymentMethod: true,
    };

    const res = (await this.send('POST', `/users/${userId}/payment_methods`, {
        extra: {
          action: addCard,
          organizationId: orgId
        },
      }, this.cache)) as DataResponse;

    if(!res){
      throw new Error('Data not found.');
    }

    return res;
  }

  public async addUser(u: User, extra?: NewUserExtra): Promise<NewUserResponse> {
    if (!u) {
      throw new Error('Object must be present.');
    }

    const cache = this.cache;

    const res = (await this.send('POST', User.getUrl(), { data: u, extra: extra }, cache)) as DataResponse;

    if (!res.data) {
      throw new Error('Data not found.');
    }

    return new NewUserResponse(res.data);
  }

  //#endregion

  //#region Utils

  public async isReady(): Promise<void> {
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      const check = function () {
        if (self.db) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }

  async sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  //#endregion
}

//#region Helper classes

class UserCache {
  accessToken: string;
  refreshToken: string;
  countryCache?: CountryCache;
  userId: string;
  user?: User;
  polled: boolean;
  private finalInitialPollPackage?: number;
  private receivedInitialPollPackages: boolean[];

  constructor(accessToken: string, refreshToken: string, userId: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.userId = userId;
    this.polled = false;
    this.receivedInitialPollPackages = [];
  }

  setUser(u: User) {
    this.user = u;
  }

  public setFinalInitialPollPackage(finalInitialPollPackage: number) {
    if (!this.polled) {
      this.finalInitialPollPackage = finalInitialPollPackage;
    }
  }

  public setReceivedInitialPollPackage(receivedInitialPollPackage: number) {
    if (!this.polled) {
      this.receivedInitialPollPackages[receivedInitialPollPackage - 1] = true;

      if (this.finalInitialPollPackage) {
        // Check if done.
        let ok = true;
        for (let i = 0; i < this.finalInitialPollPackage; i++) {
          if (!this.receivedInitialPollPackages[i]) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.polled = true;
          this.receivedInitialPollPackages = [];
          this.finalInitialPollPackage = undefined;
        }
      }
    }
  }

  public async hasPolled(): Promise<void> {
    let self = this;
    return new Promise<void>(function (resolve, reject) {
      let check = function () {
        if (self.polled) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }
}

class CountryCache {
  countryCode: CountryCode;

  polled: boolean;
  private finalInitialPollPackage?: number | null;
  private receivedInitialPollPackages: boolean[];

  constructor(countryCode: CountryCode) {
    this.countryCode = countryCode;
    this.polled = false;
    this.finalInitialPollPackage = undefined;
    this.receivedInitialPollPackages = [];
  }

  setFinalInitialPollPackage(finalInitialPollPackage: number) {
    if (!this.polled) {
      this.finalInitialPollPackage = finalInitialPollPackage;
    }
  }

  setReceivedInitialPollPackage(receivedInitialPollPackage: number) {
    if (!this.polled) {
      this.receivedInitialPollPackages[receivedInitialPollPackage - 1] = true;

      if (this.finalInitialPollPackage) {
        // Check if done.
        let ok = true;
        for (let i = 0; i < this.finalInitialPollPackage; i++) {
          if (!this.receivedInitialPollPackages[i]) {
            ok = false;
            break;
          }
        }
        if (ok) {
          this.polled = true;
          this.receivedInitialPollPackages = [];
          this.finalInitialPollPackage = null;
        }
      }
    }
  }

  async hasPolled(): Promise<void> {
    const self = this;
    return new Promise<void>(function (resolve, reject) {
      const check = function () {
        if (self.polled) {
          resolve();
          return;
        } else {
          setTimeout(check, 0); // Next slot in event loop.
        }
      };
      check();
    });
  }
}

interface SigninResponse {
  data: {
    accessToken: string;
    refreshToken: string;
    userId: string;
  };
}

interface DataRequest {
  data: any;
  extra: any;
}

export interface DataResponse {
  data: any;
  extra: any;
}

interface UserPollResponse {
  data: {
    user: any;
    products: any;
    paymentMethods: any;
    objects: any;
    payments: any;
    discounts: any;
    etag: string;
  };
  id: number;
  done: boolean;
}

interface UserPollErrorResponse {
  text: string;
}

export enum UserPollRequestType {
  Signin = 1,
  TokenRefresh = 2,
  ForcePoll = 3,
  Signout = 4,
}

export enum UserPollResponseType {
  Poll = 1,
  TokenRefresh = 2,
  Error = 3,
  ConnectionOk = 4,
  ConnectionError = 5,
}

interface CountryPollResponse {
  data: {
    stories: any;
    users: any;
    vehicleBrands: any;
    vehicleModels: any;
    productTemplates: any;
    etag: string;
  };
  id: number;
  done: boolean;
}

interface CountryPollErrorResponse {
  text: string;
}

enum CountryPollRequestType {
  Signin = 1,
  ForcePoll = 2,
  Signout = 3,
}

enum CountryPollResponseType {
  Poll = 1,
  TokenRefresh = 2, // Redundant
  Error = 3,
  ConnectionOk = 4,
  ConnectionError = 5,
}

//#endregion
